import React from 'react'

const carga = ({clase}) => {
  return (
    <div class={clase}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
  )
}

export default carga
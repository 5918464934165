import React from 'react'
import certiLogo from '../images/SimboloCerti.png'

const objetivos = () => {
  return (
    <div className='objetivos'>
      <div className='principal-objetivos'>
        <h1 className='h1-mision-vision'>Objetivos de calidad:</h1> <br/>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Identificar, evaluar y valorar todos los peligros y riesgos estableciendo sus controles y realizando seguimiento de los mismos.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Proteger la seguridad y salud de todos los trabajadores a través de la mejora continua del Sistema de Gestión de Seguridad y Salud en el Trabajo (SG-SST) de CERTICONTROL SAS</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Cumplir con la normatividad nacional vigente aplicable en materia de riesgos laborales.</h3>
          </p>
        </div>
        <h1 className='h1-mision-vision'>Objetivos de la organizacion:</h1> <br/>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Brindar servicios de inspección de alta calidad que cumplan con los requisitos establecidos por la norma NTC ISO/IEC 17020:2012 y las expectativas de nuestros clientes.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Mejorar continuamente nuestros procesos y sistemas de gestión, buscando la eficiencia y la excelencia en todas nuestras actividades.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Garantizar la satisfacción de nuestros clientes, proporcionándoles un servicio oportuno, confiable y de valor agregado.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Promover la capacitación y desarrollo de nuestro personal, asegurando que cuenten con las competencias necesarias para realizar su trabajo de manera efectiva y profesional.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Mantener una cultura organizacional basada en los principios de transparencia, ética, imparcialidad e independencia en todas nuestras operaciones.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-vision" className='img-certi-obj'/>
          <p className='p-mision-vision'>
            <h3 className='h3-mision-vision'>Cumplir con los requisitos legales y reglamentarios aplicables a nuestra industria, así como con otros compromisos voluntarios que hayamos asumido.</h3>
          </p>
        </div>
        <h1 className='h1-mision-vision'>Valores:</h1> <br/>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Calidad: Nos comprometemos a mantener los más altos estándares de calidad en todos nuestros servicios, asegurando la satisfacción de nuestros clientes.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Independencia: Actuamos con imparcialidad e independencia en todas nuestras actividades de inspección, garantizando la confiabilidad de nuestros resultados.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Confidencialidad: Manejamos toda la información con criterios de confidencialidad, asegurando la protección de los datos proporcionados por nuestros clientes y partes interesadas.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Ética: Nos regimos por principios éticos en todas nuestras acciones, actuando con integridad, honestidad y respeto hacia nuestros clientes y empleados.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Competencia: Contamos con un equipo de profesionales altamente capacitados y con experiencia en el sector, garantizando la excelencia en nuestros servicios.</h3>
          </p>
        </div>
        <div className='objetivo-cuerpo'>
          <img src={certiLogo} alt="img-mision" className='img-certi-obj'/>
          <p className='p-mision-vision'>  
            <h3 className='h3-mision-vision'>Mejora continua: Buscamos la mejora constante en nuestros procesos y servicios, adaptándonos a los cambios y requisitos del mercado, con el fin de mantenernos a la vanguardia en nuestra industria.</h3>
          </p>
        </div>
      </div>

    </div>
  )
}

export default objetivos